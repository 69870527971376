$( '#shortcode-search' ).rummage( '#shortcodes', {} );

if( 'serviceWorker' in navigator ) {
	/*navigator.serviceWorker.register( '/admin/sw.js' )
			.then( function( swReg ) {
				// console.log( 'Service Worker is registered', swReg );
			} )
			.catch( function( error ) {
				// console.error( 'Service Worker Error', error );
			} );*/
}